import React from 'react';

const ClientsTemplate = () => {
    return (
        <section className="pt70 pb70">
            <div className="container">
                <div className="row">

                    <div className="clients-slider" data-autoplay="false" data-speed="4000">
                        <div><img src="img/clients/1.png" className=" " alt="#" /></div>
                        <div><img src="img/clients/2.png" className="img-responsive" alt="#" /></div>
                        <div><img src="img/clients/3.png" className="img-responsive" alt="#" /></div>
                        <div><img src="img/clients/4.png" className="img-responsive" alt="#" /></div>
                        <div><img src="img/clients/5.png" className="img-responsive" alt="#" /></div>
                        <div><img src="img/clients/6.png" className="img-responsive" alt="#" /></div>
                        <div><img src="img/clients/7.png" className="img-responsive" alt="#" /></div>
                        <div><img src="img/clients/8.png" className="img-responsive" alt="#" /></div>
                        <div><img src="img/clients/9.png" className="img-responsive" alt="#" /></div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default ClientsTemplate;