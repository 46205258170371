import React from 'react';

import HeaderTemplate from './components/HeaderTemplate';
import HeroTemplate from './components/HeroTemplate';
import AboutTemplate from './components/AboutTemplate';
import WhoWeAreTemplate from './components/WhoWeAreTemplate';
import FunFactsTemplate from './components/FunFactsTemplate';
import QuotesTemplate from './components/QuotesTemplate';
import ServicesTemplate from './components/ServicesTemplate';
import CallOutTemplate from './components/CallOutTemplate';
import ClientsTemplate from './components/ClientsTemplate';
import ContactInfoTemplate from './components/ContactInfoTemplate';
import ContactFormTemplate from './components/ContactFormTemplate';
import MapsTemplate from './components/MapsTemplate';
import FooterTemplate from './components/FooterTemplate';

const App = () => {
    return (
        <div>
            <HeaderTemplate />
            <HeroTemplate />
            <AboutTemplate />
            <WhoWeAreTemplate />
            <FunFactsTemplate />
            <QuotesTemplate />
            <ServicesTemplate />
            <CallOutTemplate />
            <ClientsTemplate />
            <ContactInfoTemplate />
            <ContactFormTemplate />
            <MapsTemplate />
            <FooterTemplate />
        </div>
    )
}

export default App;