import React from 'react';

const ContactFormTemplate = () => {
    return (
        <section id="contact" className="pt120 pb100">
            <div className="container">
                <div className="row">

                    <div className="col-md-12 text-center pb20">
                        <h2>¡Estamos a <br/><strong> tus órdenes!</strong></h2>
                        <p className="lead">Tenemos los productos y servicios para satisfacer las <span className="color">necesidades de tu organización</span></p>
                    </div>

                    <div className="col-md-8 col-md-offset-2 contact box-style">
                        <div id="message-info"></div>
                        <form id="contactform" method="post">

                            <div className="col-sm-12">
                                <input name="name" id="name" placeholder="Su nombre *" />
                            </div>
                            <div className="col-sm-6">
                                <input name="email" id="email" placeholder="Su email *" />
                            </div>
                            <div className="col-sm-6">
                                <input name="phone" id="phone" placeholder="Su Teléfono" />
                            </div>
                            <div className="col-sm-12">
                                <textarea name="message" rows="9" id="message" placeholder="Su mensaje *"></textarea>
                            </div>
                            <div className="col-md-12">
                                <input type="submit" className="submit btn btn-lg btn-primary" id="submit" value="Enviar Mensaje" />
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default ContactFormTemplate;