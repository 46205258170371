import React from 'react';

const ServicesTemplate = () => {
    return (
        <section id="services" className="pt100 pb90">
            <div className="container">
                <div className="row">

                    <div className="col-md-12 text-center pb20">
                        <h2 className="">¿Qué solución<strong> es para mí?</strong></h2>
                        <p className="lead">Descubre la solución que <span className="color">tenemos para ti.</span>.</p>
                    </div>


                    <div className="col-md-12 pt20 pb20">

                        <div className="col-sm-6 feature-left">
                            <i className="icon-streetsign size-3x color"></i>
                            <i className="icon-streetsign back-icon"></i>
                            <div>
                                <h5><strong>Ingeniería</strong><br/>en TI</h5>
                                <p>Ofrecemos servicio de asesoria en temas de TI.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 feature-left">
                            <i className="icon-profile-male size-3x color"></i>
                            <i className="icon-profile-male back-icon"></i>
                            <div>
                                <h5><strong>Capacitación en</strong><br/>Software</h5>
                                <p>Realizamos capacitación en software.</p>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-12 pt20 pb20">

                        <div className="col-sm-6 feature-left large">
                            <i className="icon-gears size-4x color"></i>
                            <i className="icon-gears back-icon"></i>
                            <div>
                                <h4><strong>Tecnología Especializada</strong><br/>para sectores de gobierno y educación</h4>
                                <p>Controlar y elevar la calidad de la información de forma eficiente, ya es posible..</p>
                            </div>
                        </div>
                        <div className="col-sm-6 feature-left large">
                            <i className=" icon-tools size-4x color"></i>
                            <i className=" icon-tools back-icon"></i>
                            <div>
                                <h4><strong>Tecnologías</strong><br/>multiplataforma</h4>
                                <p>Realizamos tecnologías especiales según los requerimientos necesarios para usted.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default ServicesTemplate;