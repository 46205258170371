import React from 'react';

const ContactInfoTemplate = () => {
    return (
        <section className="parallax pt110 pb70" data-overlay-dark="8">
            <div className="background-image">
                <img src="img/backgrounds/bg-8.jpg" alt="#" />
            </div>
            <div className="container">
                <div className="row">

                    <div className="col-md-12 details white text-center">
                        <div className="phone-number mb10">
                            <h1 className="bold">(246) 68 85198</h1>
                        </div>
                        <div className="col-lg-12">
                            <h3>contacto@<span className="color">infornet.mx</span></h3>
                            <h4>Blvd del maestro #2707 <span className="color">La loma Xicohtencatl, Tlaxcala</span></h4>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
	
export default ContactInfoTemplate