import React from 'react';

const getYear = () => {
	var year = new Date().getFullYear();

	return year;
}
const FooterTemplate = () => {
	return (
		<footer id="footer" className="footer style-1 dark">

			<a href="index.html"><img src="img/assets/footer-logo.png" alt="#" className="mr-auto img-responsive"/></a>
			<ul>
				<li><a href="https://www.twitter.com/" target="_blank" className="color"><i className="ion-social-twitter"></i></a></li>
				<li><a href="https://www.facebook.com/" target="_blank" className="color"><i className="ion-social-facebook"></i></a></li>
				<li><a href="https://www.linkedin.com/" target="_blank" className="color"><i className="ion-social-linkedin"></i></a></li>
				<li><a href="https://www.pinterest.com/" target="_blank" className="color"><i className="ion-social-pinterest"></i></a></li>
				<li><a href="https://plus.google.com/" target="_blank" className="color"><i className="ion-social-googleplus"></i></a></li>
			</ul>
			<a href="http://themeforest.net/user/vossendesign/portfolio" target="_blank"><strong>© Infornet { getYear() }</strong></a>

			<span><a className="scroll-top"><i className="ion-chevron-up"></i></a></span>

		</footer>
	)
}

export default FooterTemplate;