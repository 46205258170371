import React from 'react';

const MapsTemplate = () => {
	return (

		<section className="parallax pt100 pb90 text-center" data-overlay="8">
            <div className="container">
                <div className="row">
					<h2><strong>¡Encuentranos!</strong></h2>
                    <div className="col-md-12">
						<iframe 
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.455003771587!2d-98.24358308505937!3d19.306052549717624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfd93fc07e32a9%3A0xa064535f7106e4fc!2sInfornet!5e0!3m2!1ses-419!2smx!4v1562870136168!5m2!1ses-419!2smx" 
							width="100%" 
							height="600" 
							frameborder="0"
							allowfullscreen />
					</div>
				</div>
			</div>
		</section>
		
	)
}
export default MapsTemplate;