import React from 'react';

const FunFactsTemplate = () => {
    return (
        <section id="fun-facts" data-animate="true">
            <div className="container-fluid">
                <div className="row">

                    <div className="col-sm-4 counter">
                        <h1 className="bold" data-count="500"></h1>
                        <h5>Clientes felices</h5>
                    </div>
                    <div className="col-sm-4 counter">
                        <h1 className="bold" data-count="60"></h1>
                        <h5>Proyectos Terminados</h5>
                    </div>
                    <div className="col-sm-4 counter">
                        <h1 className="bold" data-count="200"></h1>
                        <h5>Problemas Resueltos</h5>
                    </div>


                </div>
            </div>
        </section>
    )
}
	
export default FunFactsTemplate;