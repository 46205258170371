import React from 'react';

const HeaderTemplate = () => {
    return (
        <nav className="navbar nav-down" data-fullwidth="true" data-menu-style="transparent" data-animation="shrink">
            <div className="container">

                <div className="navbar-header">
                    <div className="container">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>
                        <a className="navbar-brand to-top" href="#">
                            <img src="img/assets/infornet.png" className="logo-light" alt="#" />
                            <img src="img/assets/infornet-light.png" className="logo-dark" alt="#" />
                        </a>
                    </div>
                </div>

                <div id="navbar" className="navbar-collapse collapse">
                    <div className="container">
                        <ul className="nav navbar-nav menu-right">

                            <li><a href="#hero">Inicio</a></li>
                            <li><a href="#about">Acerca de</a></li>
                            <li><a href="#services">Servicios</a></li>
                            <li><a href="#contact">Contacto</a></li>

                            <li className="nav-separator"></li>
                            <li className="nav-icon"><a href="http://facebook.com" target="_blank"><i className="ion-social-facebook"></i></a></li>
                            <li className="nav-icon"><a href="http://twitter.com" target="_blank"><i className="ion-social-twitter"></i></a></li>
                            <li className="nav-icon"><a href="#" target="_blank"><i className="ion-help-buoy"></i></a></li>
                        </ul>

                    </div>
                </div>
            </div>
        </nav>
    )
}

export default HeaderTemplate;