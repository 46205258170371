import React from 'react';

const QuotesTemplate = () => {
    return (
        <section id="quotes" className="parallax pt100 pb90" data-overlay-dark="8">
            <div className="background-image">
                <img src="img/backgrounds/bg-4.jpg" alt="#" />
            </div>
            <div className="container">
                <div className="row">

                    <div className="col-md-12">
                        <div className="quote-slider navigation-thin container white text-center" data-autoplay="true" data-speed="2000">
                            <div>
                                <h2>
                                    <i className="vossen-quote color"></i>
                                    La manera de empezar es <strong>dejar de hablar </strong><br/>y empezar a hacer
                                    <i className="vossen-quote color"></i>
                                </h2>
                                <p className="label label-primary">Walt Disney</p>
                            </div>
                            <div>
                                <h2>
                                    <i className="vossen-quote color"></i>
                                    La diferencia entre ordinario y extraordinario <br/>es <strong>ese poco de más</strong>
                                    <i className="vossen-quote color"></i>
                                </h2>
                                <p className="label label-primary">Jimmy Johnson</p>
                            </div>
                            <div>
                                <h2>
                                    <i className="vossen-quote color"></i>
                                    <strong>Sin compromiso </strong>no hay crecimiento
                                    <i className="vossen-quote color"></i>
                                </h2>
                                <p className="label label-primary">Kenton Wood</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
export default QuotesTemplate;