import React from 'react';

const WhoWeAreTemplate = () => {
    return (
        <section id="who-we-are" className="parallax pt40 pb40" data-overlay-dark="8">
            <div className="background-image">
                <img src="img/backgrounds/bg-2.jpg" alt="#" />
            </div>
            <div className="container">
                <div className="row vertical-align">

                    <div className="col-md-6 pr30 mt40 mb40">
                        <h2><strong>Nuestras Habilidades</strong><br/><span className="color">hechas para ti</span></h2>
                        <p>SERVICIOS ADMINISTRADOS, DIGITALIZACIÓN Y MANEJO DOCUMENTAL, INFRAESTRUCTURA COMPUTACIONAL.</p>
                        <p>Nuestro compromiso no sólo es implementarlas, sino escuchar las necesidades de tu sectoro, identificar juntos sus oportunidades y alinear la tecnología para darle el poder de desarrollar un sector cercano y moderno.
                        </p>
                    </div>

                    <div className="col-md-6 pt40 pb30">
                        <div className="progress-bars standard transparent-bars" data-animate-on-scroll="on">
                            <h5 className="bold">Help Desk</h5>
                            <div className="progress" data-percent="85%">
                                <div className="progress-bar">
                                    <span className="progress-bar-tooltip">85%</span>
                                </div>
                            </div>
                            <h5 className="bold">Experiencia de usuario</h5>
                            <div className="progress" data-percent="100%">
                                <div className="progress-bar progress-bar-primary">
                                    <span className="progress-bar-tooltip">100%</span>
                                </div>
                            </div>
                            <h5 className="bold">Digitalización de archivos históricos</h5>
                            <div className="progress" data-percent="90%">
                                <div className="progress-bar progress-bar-primary">
                                    <span className="progress-bar-tooltip">90%</span>
                                </div>
                            </div>
                            <h5 className="bold">Technology Refresh</h5>
                            <div className="progress" data-percent="92%">
                                <div className="progress-bar progress-bar-primary">
                                    <span className="progress-bar-tooltip">92%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
export default WhoWeAreTemplate