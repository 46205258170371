import React from 'react';

const CallOutTemplate = () => {
    return (
        <section className="parallax light bg-img-9" data-overlay-light="9">
            <div className="background-image">
                <img src="img/backgrounds/bg-5.jpg" alt="#"  />
            </div>
            <div className="container pt100 pb100">
                <div className="row pt20">

                    <div className="col-md-12 text-center">
                        <h2>¿Te <span className="bold">interesa</span> conocer más? </h2>
                        <p className="lead">Ponte en <span className="color">contacto</span> con nosotros y concretemos algo</p>
                        <a href="#contact" className="btn btn-md btn-primary btn-appear mt30"><span>Contáctanos<i className="ion-checkmark"></i></span></a>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default CallOutTemplate;