import React from 'react';

const HeroTemplate = () => {
    return (
        <section id="hero" className="hero-fullwidth parallax" data-overlay-dark="7">
            <div className="background-image">
                <img src="img/backgrounds/bg-1.jpg" alt="#" />
            </div>

            <div className="container">
                <div className="row">

                    <div className="hero-content-slider mt20" data-autoplay="true" data-speed="5000">

                        <div>
                            <h1>Desarrollamos<br/><strong>Ventajas Competitivas </strong></h1>
                            <p className="lead">Mediante soluciones tecnológicas y software.</p>
                            <a href="#about" className="btn btn-lg btn-primary btn-scroll">Háblanos de tu proyecto</a>
                        </div>

                        <div>
                            <h1>Desarrollando<br/><strong>Resultados </strong></h1>
                            <p className="lead">Colaboremos juntos con las soluciones que tu necesitas</p>
                            <a href="#about" className="btn btn-lg btn-primary btn-scroll">Háblanos de tu proyecto</a>
                        </div>


                    </div>

                </div>
            </div>
        </section>
    )
}
export default HeroTemplate;