import React from 'react';

const AboutTemplate = () => {
    return (
        <section id="about" className="pt100 pb90">
            <div className="container">
                <div className="row">

                    <div className="col-md-12 text-center pb20">
                        <h2>¿Por qué <strong>nosotros?</strong></h2>
                        <p className="lead">Generamos soluciones a tu medida para tener <span className="color">una mejora continua en tus procesos, </span>prevenir riesgos <br/>y acompañarte en tu crecimiento. </p>
                    </div>

                    <div className="col-sm-6 feature-left">
                        <i className="icon-puzzle size-3x color"></i>
                        <i className="icon-puzzle back-icon"></i>
                        <div className="feature-left-content">
                            <h4><strong>Arquitectura </strong><br/>tecnológica</h4>
                            <p>Queremos ser tu consultor tecnológico y acompañarte con las mejores prácticas de administración de soluciones que tu requieres.</p>
                        </div>
                    </div>
                    <div className="col-sm-6 feature-left">
                        <i className=" icon-pencil size-3x color"></i>
                        <i className=" icon-pencil back-icon"></i>
                        <div className="feature-left-content">
                            <h4><strong>Ideas</strong><br/>Inovadoras</h4>
                            <p>Ofecemos ideas inovadoras pensadas en las necesidades de cada uno de nuestros clientes, dando lugar a resultados diferentes.</p>
                        </div>
                    </div>
                    <div className="col-sm-6 feature-left">
                        <i className="icon-briefcase size-3x color"></i>
                        <i className="icon-briefcase back-icon"></i>
                        <div className="feature-left-content">
                            <h4><strong>Sinergia</strong><br/>Empresarial</h4>
                            <p>Nuestros esfuerzos en conjunto con el del cliente tiene como resultado la mayor eficiencia de los procesos involucrados.</p>
                        </div>
                    </div>
                    <div className="col-sm-6 feature-left">
                        <i className="icon-trophy size-3x color"></i>
                        <i className="icon-trophy back-icon"></i>
                        <div className="feature-left-content">
                            <h4><strong>Identificación de</strong><br/>Oportunidades</h4>
                            <p>Encontremos oportunidades de mejora que le permita llevar sus ideas mas allá, y enfocar los esfuerzos para sacar el mayor provecho posible a la infraestructura tecnológica instalada.</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default AboutTemplate;